import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import NewsCard, { NewsCardPropTypes } from "../components/card/news-card";
import Button from "../elements/button";
import linkHelper from "../helpers/link";

export const RecentNews = ({
  title = "The latest news",
  news,
  button = true,
  color = "#f68b1f",
}) => {
  if (!news || !news.length) {
    return "";
  }

  const mainNews = news[0]?.node || news[0];
  const otherNews = news.slice(1, 3);

  return (
    <div className="m-recent-news" style={{ "--border": color }}>
      <div className="m-recent-news-inner inner">
        {title && <h2 className="m-recent-news-title h4">{title}</h2>}
        <div className="m-recent-news-wrapper">
          <div className="m-recent-news-main">
            {mainNews && (
              <div className="m-recent-news-main-inner">
                <NewsCard
                  {...mainNews}
                  body={mainNews.excerpt || mainNews.text}
                  mediaType={mainNews.articleType || mainNews.mediaType}
                  link={linkHelper(mainNews?.path || mainNews?.link)}
                  topic={mainNews?.taxonomy?.topic?.title || mainNews.topic}
                  date={new Date(mainNews.publishDate || mainNews.date)}
                  largeHeading={true}
                />
              </div>
            )}
            {button && (
              <div className="m-recent-news-button -desktop">
                <Button
                  text="See more news"
                  link="/news"
                  linkType="internal"
                  includeIcon={true}
                  type="secondary"
                />
              </div>
            )}
          </div>
          <div className="m-recent-news-other">
            {otherNews &&
              otherNews.map((item, i) => {
                item = item.node || item;
                return (
                  <NewsCard
                    {...item}
                    body={item.excerpt || item.text}
                    mediaType={item.articleType || item.mediaType}
                    link={linkHelper(item?.path || item?.link)}
                    topic={item?.taxonomy?.topic?.title || item.topic}
                    date={new Date(item.publishDate || item.date)}
                    key={i}
                  />
                );
              })}
          </div>
        </div>
        {button && (
          <div className="m-recent-news-button -mobile">
            <Button
              text="See more news"
              link="/news"
              linkType="internal"
              includeIcon={true}
              type="secondary"
            />
          </div>
        )}
      </div>
    </div>
  );
};

RecentNews.propTypes = {
  title: PropTypes.string,
  news: PropTypes.arrayOf(PropTypes.shape(NewsCardPropTypes)),
  button: PropTypes.bool,
  color: PropTypes.string,
};

export default function RecentNewsQuery(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          recent: allSanityArticle(
            sort: { order: DESC, fields: publishDate }
            limit: 3
          ) {
            edges {
              node {
                excerpt
                publishDate
                articleType
                slug {
                  current
                }
                path
                taxonomy {
                  topic {
                    title
                  }
                  tags {
                    title
                  }
                }
                title
              }
            }
          }
        }
      `}
      render={data => {
        const newsData = data.recent.edges;
        return <RecentNews news={newsData} {...props} />;
      }}
    />
  );
}

RecentNewsQuery.propTypes = {
  type: PropTypes.string,
};
