/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { SearchIcon } from "../helpers/icons";
import algoliasearch from "algoliasearch/lite";
import React, { useState, useEffect } from "react";
import TextInput from "../elements/text-input";
import {
  InstantSearch,
  connectSearchBox,
  connectStateResults,
  connectPagination,
  connectHits,
  connectRefinementList,
  connectSortBy,
} from "react-instantsearch-dom";
import NewsListItem from "../components/card/news-list-item";

const appId = process.env.GATSBY_ALGOLIA_APP_ID;
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY;
const searchClient = algoliasearch(appId, searchKey);
let currentPostType = "";
let currentSortType = "";

const NewsSearch = () => {
  const [searchState, setSearchState] = useState({
    query: "",
    filters: "type:",
  });

  const PageHit = ({ hits }) => {
    return (
      hits?.length > 0 &&
      hits.map((hit, i) => {
        return (
          <div className="m-featured-list-item" key={i}>
            <NewsListItem
              title={hit.title}
              link={"path" in hit ? hit.path : hit.externalLink}
              date={new Date(hit.date)}
              topic={hit.topic?.title}
              mediaType={hit.articleType}
              body={hit.excerpt}
              publication={hit?.publication}
            />
          </div>
        );
      })
    );
  };

  const RefinementList = ({ items, refine }) => {
    const handleTypeChange = value => {
      currentPostType = value;
      refine(value);
    };

    return (
      <div className="field select">
        <label htmlFor="post-type" className="label -hide">
          Select a post type
        </label>
        <div className="control"></div>
        <select
          onChange={e => handleTypeChange(e.target.value)}
          value={currentPostType}
        >
          <option value="">Filter by Type</option>
          {items?.[0] &&
            items.map(item => (
              <option key={item.label} value={item.label}>
                {item.label} ({item.count})
              </option>
            ))}
        </select>
      </div>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementList);

  const SortBy = ({ items, refine }) => {
    const handleTypeChange = value => {
      currentSortType = value;
      refine(value);
    };

    return (
      <div className="field select">
        <label htmlFor="post-type" className="label -hide">
          Sort results
        </label>
        <div className="control"></div>
        <select
          onChange={e => handleTypeChange(e.target.value)}
          value={currentSortType}
        >
          {items?.[0] &&
            items.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
        </select>
      </div>
    );
  };

  const CustomSortBy = connectSortBy(SortBy);

  const CustomHits = connectHits(PageHit);

  const Pagination = ({ currentRefinement, nbPages, refine }) => {
    const currentPage = currentRefinement;
    return (
      <ul className="m-search-pagination">
        {/* Left navigation arrow */}
        <li
          className={`m-search-paginate-button${
            currentPage === 1 ? " -disabled" : ""
          }`}
        >
          <a
            className="m-search-paginate-link"
            href="#search-list"
            onClick={e => {
              refine(1);
            }}
          >
            &lt;&lt;
          </a>
        </li>
        <li
          className={`m-search-paginate-button${
            currentPage === 1 ? " -disabled" : ""
          }`}
        >
          <a
            className="m-search-paginate-link"
            href="#"
            onClick={e => {
              refine(currentPage - 1);
            }}
          >
            &lt;
          </a>
        </li>
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1;
          return (
            page >= currentRefinement - 2 &&
            page <= currentRefinement + 2 && (
              <li
                key={index}
                className={`m-search-paginate-button${
                  currentRefinement === page ? " -active" : ""
                }`}
              >
                <a
                  className="m-search-paginate-link"
                  href="#search-list"
                  onClick={e => {
                    refine(page);
                  }}
                >
                  {page}
                </a>
              </li>
            )
          );
        })}

        {/*  Right Navigation arrow */}
        <li
          className={`m-search-paginate-button${
            currentPage === nbPages ? " -disabled" : ""
          }`}
        >
          <a
            className="m-search-paginate-link"
            href="#search-list"
            onClick={e => {
              refine(currentPage + 1);
            }}
          >
            &gt;
          </a>
        </li>
        <li
          className={`m-search-paginate-button${
            currentPage === nbPages ? " -disabled" : ""
          }`}
        >
          <a
            className="m-search-paginate-link"
            href="#search-list"
            onClick={e => {
              refine(nbPages);
            }}
          >
            &gt;&gt;
          </a>
        </li>
      </ul>
    );
  };
  const CustomPagination = connectPagination(Pagination);

  const CustomResults = connectStateResults(
    ({ searchState, searchResults }) => {
      return (
        <div className="m-featured-list">
          <div className="m-featured-list-inner">
            <h4 className="m-search-results-count">
              {searchResults?.query
                ? `News related to "${searchResults.query}"`
                : "All news & resources"}

              {searchResults && (
                <span>
                  {`${
                    searchResults?.nbHits
                      ? searchResults.nbHits.toLocaleString()
                      : "0"
                  }`}
                </span>
              )}
            </h4>
            <div className="m-search-filters">
              <form
                className="m-search-form"
                name="search"
                onSubmit={e =>
                  handleSubmit(e, e.currentTarget.elements?.search?.value)
                }
              >
                <TextInput placeholder={"Search News..."} name={"search"} />
                <a
                  className="m-search-button"
                  href="#"
                  onClick={e =>
                    handleSubmit(
                      e,
                      e.currentTarget?.parentNode?.elements?.search?.value
                    )
                  }
                >
                  <SearchIcon className="m-search-button-icon" />
                </a>
              </form>
              <CustomRefinementList
                attribute="articleType"
                operator="or"
                defaultRefinement={
                  currentPostType ? new Array(currentPostType) : null
                }
              />
              <CustomSortBy
                defaultRefinement="news_date_desc"
                items={[
                  { value: "news_date_desc", label: "Most Recent" },
                  { value: "news_date_asc", label: "Oldest" },
                ]}
              />
              <a
                href="#"
                className="e-button -tertiary"
                onClick={e => {
                  handleSubmit(e, "");
                  currentPostType = "";
                  currentSortType = "";
                }}
              >
                <span>Reset</span>
              </a>
            </div>
            <div className="m-search-hidden-anchor" id="search-list"></div>
            {searchResults?.nbHits > 0 && (
              <div className="m-search-results">
                <CustomHits />
              </div>
            )}
            {!searchResults?.nbHits > 0 && (
              <div className="m-search-no-results">
                No results found for{" "}
                {searchResults?.query ? searchResults.query : "your query"}
              </div>
            )}
            <CustomPagination />
          </div>
        </div>
      );
    }
  );

  const handleSubmit = (e, value) => {
    e.preventDefault();
    setSearchState({ ...searchState, query: value });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let searchTerm = params.get("q");
      if (searchTerm === "submission") {
        currentPostType = "Policy";
      }
    }
    return () => {};
  }, []);

  const SearchBox = ({ currentRefinement, refine }) => (
    <input
      type="search"
      defaultValue={searchState.query}
      style={{
        visibility: "hidden",
        height: "0",
        width: "0",
        fontSize: "0",
        padding: "0",
        border: "none",
      }}
    />
  );

  const CustomSearchBox = connectSearchBox(SearchBox);

  return (
    <div className="m-search">
      <div className="m-search-inner container">
        <InstantSearch searchClient={searchClient} indexName={"News"}>
          <CustomSearchBox defaultRefinement={searchState.query} />
          <CustomResults />
        </InstantSearch>
      </div>
    </div>
  );
};

export default NewsSearch;
