const formatFeaturedArticle = article => {
  let { path, taxonomy, articleType, publishDate, ...rest } = article;

  return {
    link: path,
    topic: taxonomy?.topic?.title,
    mediaType: articleType,
    date: publishDate,
    ...rest,
  };
};

const formatFeaturedArticlesList = articles =>
  articles.map(article => formatFeaturedArticle(article));

export { formatFeaturedArticle, formatFeaturedArticlesList };
