import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../layouts";
import SEO from "../components/seo";
import Header from "../modules/header";
import RecentNews from "../modules/recent-news";
import NewsSearch from "../modules/search-news";
import { formatFeaturedArticlesList } from "../helpers/article";

export const query = graphql`
  query NewsPageQuery($id: String!) {
    page: sanityPageNews(id: { eq: $id }) {
      title
      slug {
        current
      }

      seo {
        title
        keywords
        image {
          asset {
            url
          }
        }
        description
      }
      breadcrumbs {
        title
        slug
      }
      heroTitle
      heroText
      mediaContactEmail
      mediaContactName
      mediaContactPhone
      mediaContactRole
      mediaContactTitle
      featuredNewsTitle
      featured {
        _id
        title
        articleType
        path
        publishDate
        taxonomy {
          topic {
            title
            parentTopic
          }
        }
      }
    }
  }
`;

const NewsIndex = ({ data }) => {
  const page = data.page;
  const contact = {
    title: page.mediaContactTitle,
    name: page.mediaContactName,
    role: page.mediaContactRole,
    phone: page.mediaContactPhone,
    email: page.mediaContactEmail,
  };

  return (
    <Layout className="news-index">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
      />
      <Header
        title={page.heroTitle}
        text={page.heroText}
        breadcrumbs={page.breadcrumbs}
        contact={contact}
      />
      <section className="p-news-body">
        <RecentNews
          title={page.featuredNewsTitle}
          news={formatFeaturedArticlesList(page.featured)}
          button={false}
        />
        <NewsSearch />
      </section>
    </Layout>
  );
};

NewsIndex.propTypes = {
  data: PropTypes.object,
};

export default NewsIndex;
